
.upload-btn:hover{
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}


@media (max-width: 768px) {
    .cards{
        width: 100%;
        height: 200vh;
        background-color: #303034;
    }

    .responsive-card{
        width: 80%;
        height: 50vh;
        background-color: #F6F6F6;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .cards{
        width: 100%;
        height: 80vh;
        background-color: #303034;
    }

    .responsive-card{
        width: 30%;
        height: 40%;
        background-color: #F6F6F6;
    }
}

@media (min-width: 1200px) {
    .cards{
        width: 100%;
        height: 60vh;
        background-color: #303034;
    }
    
    .responsive-card{
        width: 20%;
        height: 70%;
        background-color: #F6F6F6;
    }
}
