.settings-container{
    width: 100%;
    height: 100vh;
}

.settings-main{
    background-color: white;
    width: 90%;
    height: 50vh;
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}


.settings-tile:hover{
    cursor: pointer;
    background-color: #DFE0E2;
}


.break-line{
    white-space: nowrap
}

.theme-tile-dark{
    display: flex;
    align-items: flex-end;
    background-color: #303034;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.theme-tile-light{
    display: flex;
    align-items: flex-end;
    background-color: #DFE0E2;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.item-1{
    width: 100%;
    height: 75%;
    border-radius: 15px;
    background-color: white;
}

.item-2{
    position: relative;
    top: 60%;
    float: right;
    width: 30%;
    height: 10%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #4285F4;
}

.item-3{
    position: relative;
    top: 30%;
    float: left;
    width: 70%;
    height: 20%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #303034;
}

.theme-tile-dark:hover{
    box-shadow: 0 20px 30px rgba(0,0,0,.15), 0 9px 8px rgba(0,0,0,.07);
}

.theme-tile-light:hover{
    box-shadow: 0 20px 30px rgba(0,0,0,.15), 0 9px 8px rgba(0,0,0,.07);
}

@media (max-width: 768px) {
    .tile{
        width: 80%;
        height: auto;
        font-size: 10px;
    }
    .menu-tile{
        margin: 0;
        padding: 0;
        width: 40%;
        height: 40vh;
        margin-left: 10px;
        border-right: 1px solid black;
    }
    .tiles-flex{
        display: flex;
        flex-direction: row;
    }
    .settings-icon{
        font-size: 20px;
    }
    .settings-p{
        font-size: 20px;
    }
    .settings-main{
        height: 80vh;
    }
    .theme{
        width: 150px;
        height: 100px;
    }
    .tile-settings{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .tile{
        width: 60%;
        height: auto;
    }
    .menu-tile{
        margin: 0;
        padding: 0;
        width: 25%;
        height: 40vh;
        margin-left: 20px;
        border-right: 1px solid black;
    }
    .tiles-flex{
        display: flex;
        flex-direction: row;
    }
    .settings-icon{
        font-size: 30px;
    }
    .settings-p{
        font-size: 30px;
    }
    .settings-main{
        height: 100vh;
    }
    .theme{
        width: 70%;
        height: 20vh;
    }
    .tile-settings{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;
    }
}

@media (min-width: 1200px) {
    .tile{
        width: 60%;
        height: auto;
    }
    .menu-tile{
        margin: 0;
        padding: 0;
        width: 15%;
        height: 40vh;
        margin-left: 30px;
        border-right: 1px solid black;
    }
    .tiles-flex{
        display: flex;
        flex-direction: row;
    }
    .settings-icon{
        font-size: 25px;
    }
    .settings-p{
        font-size: 25px;
    }
    .theme{
        width: 20%;
        height: 20vh;
    }
    .tile-settings{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }
}