.header{
    height: 10vh;
    background-color: #303034;
    color: white;
    z-index: 1;
}

.side-navbar-hover{
  position: fixed;
  top: 0;
  right: 0vh;
  width: 20%;
  height: 100vh;
}

.side-navbar-transform{
  transform: translateX(-50vh);
}

.dropdown-hover-dark:hover{
  color: #303034;
  background-color: #DFE0E2;
}

.dropdown-hover-light:hover{
  color: #DFE0E2;
  background-color: #575757;
}


@media (max-width: 768px) {
  .side-navbar{
    position: fixed;
    top: 0;
    right: 0vh;
    width: 45%;
    height: 100vh;
    background-color: #303034;
    text-align: center;
    transition: all 1s ease-in-out;
    z-index: 5;
  }
  .linked{
    padding-left: 10px;
    text-decoration: none;
    cursor: pointer;
  }
  .username-text{
    font-size: 20px;
  }
  .email-text{
    font-size: 10px;
  }
  .user-info{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .side-navbar{
    position: fixed;
    top: 0;
    right: 0vh;
    width: 25%;
    height: 100vh;
    background-color: #303034;
    text-align: center;
    transition: all 1s ease-in-out;
    z-index: 5;
  }
  .linked{
    padding-left: 20px;
    text-decoration: none;
    cursor: pointer;
  }
  .username-text{
    font-size: 25px;
  }
  .email-text{
    font-size: 15px;
  }
  .user-info{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .side-navbar{
    position: fixed;
    top: 0;
    right: 0vh;
    width: 20%;
    height: 100vh;
    background-color: #303034;
    text-align: center;
    transition: all 1s ease-in-out;
    z-index: 5;
  }
  .linked{
    padding-left: 50px;
    text-decoration: none;
    cursor: pointer;
  }
  .username-text{
    font-size: 25px;
  }
  .email-text{
    font-size: 15px;
  }
  .user-info{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
  }
}