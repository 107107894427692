html, body{
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.App {
  text-align: center;
}


.max-vh{
  height: 100vh;
}

.linked{
  color: white;
  text-decoration: none;
}

.linked:hover{
  color: white;
  text-decoration: none;
}


