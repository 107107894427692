.main{
    width: 100%;
    height: 80vh;
}

.a-underline{
    color: blue;
    text-decoration: underline;
}

.shadow{
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.submit-btn:hover{
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

@media (max-width: 768px) {
    .calendar{
        width: 90%;
        height: 200vh;
    }
    .bg-calendar{
        width: 100%;
        height: 150vh;
        box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    }
    .login-card{
        width: 70%;
        box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    }
    .register{
        height: 120vh;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .calendar{
        width: 90%;
        height: 200vh;
    }
    .bg-calendar{
        width: 100%;
        height: 100vh;
        box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    }
    .login-card{
        width: 40%;
        box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    }
    .register{
        height: 80vh;
    }
}

@media (min-width: 1200px) {
    .calendar{
        width: 90%;
        height: 120vh;
    }
    .bg-calendar{
        width: 100%;
        height: 100vh;
        box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    }
    .login-card{
        width: 25%;
        box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    }
    .register-card{
        width: 25%;
        box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    }
    .register{
        height: 100vh;
    }
}